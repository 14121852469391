import * as React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { navigate, Link as UnlocalizedLink } from 'gatsby'

import Link from '../components/localized-link'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Headroom from 'react-headroom'
import ScrollLock from 'react-scrolllock'
import { Scrollchor } from 'react-scrollchor'

import IconBurger from '../icons/icon-burger.svg'
import IconClose from '../icons/icon-close.svg'

import { heights, dimensions, colors, mediaqueries } from '../styles/variables'
import SegmentedControls from './segmented-controls'

import languages from '../../config/i18n.js'
import useTranslation from './use-translation'

export interface HeaderProps {
  title: string
  logo: IGatsbyImageData
  locale: string
  isHome: boolean
}

interface StyledHeaderProps {
  fix: boolean
}

const StyledHeader = styled.header<StyledHeaderProps>`
  height: ${heights.header}px;
  padding: 0 ${dimensions.containerPadding.sm}rem;
  background-color: ${colors.white};
  color: ${transparentize(0.5, colors.white)};
  border-bottom: 1px solid #e7e7e7;
  z-index: 1;
  width: 100%;
  position: ${(props) => (props.fix ? 'fixed' : 'relative')};
`

const StyledHeaderInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`

const StyledLogoLink = styled(Link)`
  line-height: 0;
`

const StyledNavigationDesktop = styled.nav`
  display: none;
  align-items: center;

  ${mediaqueries.lg} {
    display: flex;
  }

  > a:not(:last-child) {
    margin-right: 1.5rem;
  }
`

const StyledNavigationMobileToggle = styled.div`
  line-height: 0;
  color: ${colors.blue.dark};

  ${mediaqueries.lg} {
    display: none;
  }
`

const StyledNavigationMobile = styled.div`
  position: fixed;
  background-color: ${colors.white};
  height: calc(100vh - ${heights.header}px);
  left: 0;
  top: ${heights.header}px;
  width: 100vw;
  z-index: 99;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  color: ${colors.blue.dark};

  > * {
    padding: 1rem 0.5rem;
  }

  ${mediaqueries.lg} {
    display: none;
  }
`

const StyledUnlocalizedLink = styled(UnlocalizedLink)`
  font-size: ${dimensions.fontSize.small}px;
  color: ${colors.blue.darkest};
  text-decoration: none;
  :focus,
  :hover {
    text-decoration: underline;
  }
`

const StyledLink = styled(Link)`
  font-size: ${dimensions.fontSize.small}px;
  color: ${colors.blue.darkest};
  text-decoration: none;
  :focus,
  :hover {
    text-decoration: underline;
  }
`

const StyledScrollchor = styled(Scrollchor)`
  font-size: ${dimensions.fontSize.small}px;
  color: ${colors.blue.darkest};
  text-decoration: none;
  :focus,
  :hover {
    text-decoration: underline;
  }
`

const Header: React.FC<HeaderProps> = ({ logo, locale, isHome }) => {
  const [navigationOpen, setNavigationOpen] = React.useState(false)

  const { menu_open_letter, menu_petition, menu_mission, menu_alternatives, menu_supporters, menu_about } = useTranslation()

  const handleNavigationToggleClick = () => {
    setNavigationOpen(!navigationOpen)
  }

  const locales: { [key: string]: any } = languages

  const NavItems = ({ isHome }: { isHome: boolean }) => (
    <>
      <StyledUnlocalizedLink to="/open-letter">{menu_open_letter}</StyledUnlocalizedLink>
      {isHome ? (
        <StyledScrollchor to="#petition">{menu_petition}</StyledScrollchor>
      ) : (
        <StyledLink to="/#petition">{menu_petition}</StyledLink>
      )}
      {isHome ? <StyledScrollchor to="#mission">{menu_mission}</StyledScrollchor> : <StyledLink to="/#mission">{menu_mission}</StyledLink>}
      {isHome ? (
        <StyledScrollchor to="#alternatives">{menu_alternatives}</StyledScrollchor>
      ) : (
        <StyledLink to="/#alternatives">{menu_alternatives}</StyledLink>
      )}
      {isHome ? (
        <StyledScrollchor to="#supporters">{menu_supporters}</StyledScrollchor>
      ) : (
        <StyledLink to="/#supporters">{menu_supporters}</StyledLink>
      )}
      {isHome ? <StyledScrollchor to="#about">{menu_about}</StyledScrollchor> : <StyledLink to="/#about">{menu_about}</StyledLink>}
    </>
  )

  return (
    <Headroom style={{ zIndex: 999 }}>
      <StyledHeader fix={navigationOpen}>
        <StyledHeaderInner>
          <StyledLogoLink to="/">
            <GatsbyImage image={logo} alt="Clean Coffee Project" />
          </StyledLogoLink>
          <StyledNavigationDesktop>
            <NavItems isHome={isHome} />
            <SegmentedControls
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation()
              }}
              onChange={(value) => {
                navigate(locales[value].default ? '/' : `/${value}`)
              }}
              value={locale}
              options={Object.keys(locales).map((lang) => ({
                name: lang,
                label: lang.toUpperCase(),
                value: lang
              }))}
            />
          </StyledNavigationDesktop>
          <StyledNavigationMobileToggle onClick={handleNavigationToggleClick}>
            {navigationOpen ? <IconClose /> : <IconBurger />}
          </StyledNavigationMobileToggle>
          {navigationOpen && (
            <ScrollLock>
              <StyledNavigationMobile onClick={handleNavigationToggleClick}>
                <NavItems isHome={isHome} />
                <SegmentedControls
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation()
                  }}
                  onChange={(value) => {
                    navigate(locales[value].default ? '/' : `/${value}`)
                    handleNavigationToggleClick()
                  }}
                  value={locale}
                  options={Object.keys(locales).map((lang) => ({
                    name: lang,
                    label: lang.toUpperCase(),
                    value: lang
                  }))}
                />
              </StyledNavigationMobile>
            </ScrollLock>
          )}
        </StyledHeaderInner>
      </StyledHeader>
    </Headroom>
  )
}

export default Header

import * as React from 'react'
import styled from '@emotion/styled'
import { colors, dimensions } from '../styles/variables'

export interface SegmentedControlsProps {
  options: SegmentedControlsOption[]
  value: string | number
  onChange: (value: string | number) => void
  size?: 'sm' | 'md' | 'lg'
  block?: boolean
  [key: string]: any
}

export interface SegmentedControlsOption {
  name: string
  label: string | React.ReactNode
  value: string | number
  checked?: boolean
}

export interface SegmentProps extends StyledInputProps {
  label: string | React.ReactNode
  first?: boolean
  last?: boolean
  onChange: (event: React.ChangeEvent) => void
}

export interface StyledSegmentedControlsProps {
  block?: boolean
}

export interface StyledInputProps {
  name: string
  checked?: boolean
  first?: boolean
  last?: boolean
  segmentSize: SegmentedControlsProps['size']
}

const paddings = {
  sm: '4px 8px',
  md: '8px 16px',
  lg: '16px 32px'
}

const StyledSegmentedControls = styled.div<StyledSegmentedControlsProps>`
  display: flex;
  justify-content: ${(props) => (props.block ? 'stretch' : 'auto')};
`

const StyledInput = styled.input<StyledInputProps>`
  display: none;

  & + label {
    background-color: ${colors.blue.dark};
    color: ${colors.white};
    padding: ${(props) => (props.segmentSize ? paddings[props.segmentSize] : paddings.sm)};
    font-size: ${dimensions.fontSize.small}px;
    cursor: pointer;
    margin-right: 1px;
    display: flex;
    flex-grow: 1;

    & :first-of-type {
      border-radius: 5px 0 0 5px;
    }

    & :last-of-type {
      margin-right: 0;
      border-radius: 0 5px 5px 0;
    }
  }

  &:checked + label {
    background-color: ${colors.blue.darkest};
    cursor: default;
  }
`

const StyledLabel = styled.label`
  margin: 0;
`

const Segment: React.FC<SegmentProps> = ({ name, label, ...rest }) => {
  return (
    <>
      <StyledInput type="radio" name={name} id={name} {...rest} />
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
    </>
  )
}

const SegmentedControls: React.FunctionComponent<SegmentedControlsProps> = ({ options, value, onChange, size, block, ...rest }) => {
  const [selectedValue, setSelectedValue] = React.useState(value)

  return (
    <StyledSegmentedControls block={block} {...rest}>
      {options.map((option, index) => (
        <Segment
          key={option.name}
          {...option}
          checked={selectedValue === option.value}
          first={index === 0}
          last={index === options.length - 1}
          segmentSize={size}
          onChange={() => {
            setSelectedValue(option.value)
            onChange && onChange(option.value)
          }}
        />
      ))}
    </StyledSegmentedControls>
  )
}

SegmentedControls.defaultProps = {
  size: 'sm'
}

export default SegmentedControls

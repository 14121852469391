import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LocaleContext } from '../layouts'

interface LocaleNode {
  name: string
  translations: {
    [key: string]: string
  }
}

interface LocaleEdge {
  node: LocaleNode
}

function useTranslation() {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  const { data } = useStaticQuery(query)

  // Simplify the response from GraphQL
  const simplified = data.edges.map((item: LocaleEdge) => {
    return {
      name: item.node.name,
      translations: item.node.translations
    }
  })

  // Only return translations for the current locale
  const { translations } = simplified.filter((lang: LocaleNode) => lang.name === locale)[0]

  return translations
}

export default useTranslation

const query = graphql`
  query useTranslations {
    data: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            menu_open_letter
            menu_petition
            menu_mission
            menu_alternatives
            menu_supporters
            menu_about
            banderole_paragraph
            banderole_button
            hero_headline
            petition_headline
            petition_paragraph_1
            petition_paragraph_2
            petition_paragraph_3
            petition_paragraph_3_link
            petition_paragraph_4
            petition_signatures_counter
            petition_text
            petition_form_firstname
            petition_form_lastname
            petition_form_email
            petition_form_zip
            petition_form_country
            petition_form_terms
            petition_form_terms_link
            petition_form_button_submit
            petition_form_button_share
            petition_form_error
            petition_submit_error
            petition_submit_success
            mission_headline
            mission_paragraph_1
            mission_paragraph_2
            mission_paragraph_3
            mission_paragraph_3_emphasis
            mission_paragraph_4
            mission_reason_1
            mission_reason_2
            mission_reason_3
            mission_reason_4
            mission_reason_5
            alternatives_headline
            alternatives_paragraph_1
            alternatives_paragraph_2
            alternatives_paragraph_2_link
            supporters_headline
            join_headline
            join_paragraph_1
            join_paragraph_2
            join_paragraph_3
            join_paragraph_3_link
            about_headline
            about_paragraph_1
            about_paragraph_link
            footer_copyright
            privacy_headline
            privacy_p1_subline
            privacy_p1_text
            privacy_p2_subline
            privacy_p2_text
            privacy_p3_subline
            privacy_p3_text
            privacy_p4_subline
            privacy_p4_text
            privacy_p4_b1
            privacy_p4_b2
            privacy_p4_b3
            privacy_p4_b4
            privacy_p4_b5
            privacy_p5_subline
            privacy_p5_text
            privacy_p5_b1
            privacy_p5_b2
            privacy_p5_b3
            privacy_p6_subline
            privacy_p6_text
            privacy_p7_subline
            privacy_p7_text
          }
        }
      }
    }
  }
`

import styled from '@emotion/styled';
import GeorgeImage from "../../../../src/components/georgeImage";
import IconLetter from "../../../../src/icons/icon-letter.svg";
import IconBubble from "../../../../src/icons/icon-bubble.svg";
import * as React from 'react';
export default {
  styled,
  GeorgeImage,
  IconLetter,
  IconBubble,
  React
};
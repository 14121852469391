import React from 'react'
import { Link } from 'gatsby'
import { LocaleContext } from '../layouts'
import locales from '../../config/i18n'

export interface LocalizedLinkProps {
  to: string
  [key: string]: any
}

// Use the globally available context to choose the right path
const LocalizedLink: React.FC<LocalizedLinkProps> = ({ to, ...props }) => {
  const { locale } = React.useContext(LocaleContext)

  const isIndex = to === `/`

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  const localeData: { [key: string]: any } = locales
  const path = localeData[locale].default ? to : `/${localeData[locale].path}${isIndex ? `` : `${to}`}`

  return <Link {...props} to={path} />
}

export default LocalizedLink

import * as React from 'react'
import styled from '@emotion/styled'

const StyledGeorgeImage = styled.div`
  max-width: 400px;
  margin: 2rem auto 0;
  img {
    position: absolute;
    top: 0;
  }
`

const GeorgeImage: React.FC = props => {
  return <StyledGeorgeImage {...props} />
}

export default GeorgeImage

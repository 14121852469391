import * as React from 'react'
import styled from '@emotion/styled'

import { dimensions, colors, widths, mediaqueries } from '../styles/variables'
import useTranslation from './use-translation'

import IconFacebook from '../icons/icon-facebook.svg'
import IconInstagram from '../icons/icon-instagram.svg'

const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${colors.black};
  color: ${colors.white};
`

const StyledFooterInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${widths.lg}rem;
  justify-content: space-between;
  padding: 1.5rem;

  ${mediaqueries.md} {
    padding: ${dimensions.containerPadding.lg}rem;
  }
`

const StyledParagraph = styled.p`
  margin: 0 1rem 0 0;
`

const StyledSocialIcons = styled.div`
  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`

const StyledSocialIcon = styled.a`
  transition: all 0.25s ease;
  opacity: 0.5;
  line-height: 0;

  &:hover {
    opacity: 1;
  }
`

const Header: React.FC = () => {
  const { footer_copyright } = useTranslation()
  return (
    <StyledFooter>
      <StyledFooterInner>
        <StyledParagraph>
          <small>{footer_copyright.replace('{{year}}', new Date().getFullYear())}</small>
        </StyledParagraph>
        <StyledSocialIcons>
          <StyledSocialIcon href="https://www.facebook.com/cleancoffeeproject.org/?fref=ts" target="blank">
            <IconFacebook />
          </StyledSocialIcon>
          <StyledSocialIcon href="https://www.instagram.com/cleancoffeeproject/" target="blank">
            <IconInstagram />
          </StyledSocialIcon>
        </StyledSocialIcons>
      </StyledFooterInner>
    </StyledFooter>
  )
}

export default Header

import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import 'modern-normalize'
import '../styles/normalize'

import Header from '../components/header'
import Footer from '../components/footer'
import LayoutRoot from './layout-root'
import LayoutMain from './layout-main'

export interface LayoutProps {
  children: React.ReactNode
  pageContext: {
    locale: string
    isHome: boolean
  }
}

const LocaleContext = React.createContext({ locale: 'en' })

const Layout: React.FC<LayoutProps> = ({ children, pageContext: { locale, isHome } }) => {
  const data = useStaticQuery(query)

  return (
    <LocaleContext.Provider value={{ locale }}>
      <LayoutRoot>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
        />
        <Header title={data.site.siteMetadata.title} logo={data.file.childImageSharp.gatsbyImageData} locale={locale} isHome={isHome} />
        <LayoutMain>{children}</LayoutMain>
        <Footer />
      </LayoutRoot>
    </LocaleContext.Provider>
  )
}

export default Layout

export { LocaleContext }

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    file(relativePath: { eq: "logos/logo-type.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 203, height: 40)
      }
    }
  }
`
